import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Full Clean 4-4-4-4\\@80% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`Buy In: 50 Wall Balls (20/14`}{`#`}{`)`}</p>
    <p>{`4 Rounds of:`}</p>
    <p>{`15-Calorie Row`}</p>
    <p>{`15-Power Cleans (135/95)`}</p>
    <p>{`Cash Out: 50 Wall Balls`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The sign up sheet for Memorial Day Murph is up next to the front
white board.  We have 15 slots each half hour starting at 10:30am (10:30
slot is filled) on the 28th so sign up ahead of time!  If you are a
visitor and won’t be in before Murph you can email us and we’ll sign you
up.  All other class times and open gym are cancelled for the day.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      